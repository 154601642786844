body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --gold: #996741;
  --gold-dark: #5c3e27;
  --gold-light: #c2a48d;
  --gold-grey: #42403f;
  --cream: #b9beaa;
  --cream-dark: #828577;
  --cream-light: #edefe5;
}