.hero {
  position: relative;
  height: 100vh;
  background: url('../images/IMG_1380.JPEG') no-repeat center center/cover;
  color: white;
}

.hero-overlay {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 60%, #161616f0 85%);
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.hero-content-wrapper {
  max-width: 1350px;
  margin-inline: auto;
  position: relative;
  height: 100%;
  z-index: 2;
}

.logo {
  height: 180px;
  position: absolute;
  top: 0px;
  left: 20px;
}

.nav {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100px;
}

.nav .desktop-menu {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav li {
  margin-right: 20px;
}

.nav a {
  text-decoration: none;
  font-size: 1rem;
  color: white;
  padding: 15px 20px;
}

.nav a:hover {
  color: var(--cream-light);
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  margin: 20px 20px 0 0;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.cta-button {
  background-color: var(--gold);
  color: white;
  padding: 12px 25px;
  text-decoration: none;
  margin-top: 20px;
  display: inline-block;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gold);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.mobile-menu .close-button {
  position: absolute;
  top: 40px;
  right: 40px;
  background: none;
  border: none;
  color: var(--cream-light);
  font-size: 2rem;
  cursor: pointer;
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.mobile-menu li {
  margin: 20px 0;
}

.mobile-menu a {
  text-decoration: none;
  font-size: 2rem;
  font-weight: 200;
  color: var(--cream-light);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero {
    height: 80vh;
  }

  .logo {
    height: 140px;
    top:0px;
    left: 0px;
  }

  .hero-overlay {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2) 20%, #161616 100%);
  }

  .hero h1{
    font-size: 1.6rem;
  }
  .nav .desktop-menu {
    display: none;
  }

  .mobile-menu-button {
    display: block;
  }
}
