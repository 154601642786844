.work-overview {
  padding: 50px 20px;
  background-color: var(--cream);
}

.work-overview h2 {
  font-size: 2.4em;
  margin-bottom: 20px;
  text-align: center;
  color: var(--cream-light);
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap:20px;
}

.card {
  width: 300px;
  margin: 10px;
  padding: 10px;
  text-align: left;
  background-color: var(--cream-light);
}

.card img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.card h3 {
  font-size: 1.5em;
  margin: 10px 0;
  color: var(--gold);
}

.card p {
  font-size: 1em;
  line-height: 1.6;
  margin: 0;
  color: var(--gold-dark);
}
