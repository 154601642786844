.important-work {
  background-color: var(--cream-light);
}

.important-work-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1350px;
  padding: 40px 0;
  margin-inline: auto;
  position: relative;
  height: 100%;
}

.important-work-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1350px;
  padding: 40px 0;
  margin-inline: auto;
  position: relative;
  height: 100%;
}

.important-work img {
  width: 100%;
  max-width: 500px;
  height: 500px;
  margin-right: 20px;
  object-fit: cover;
}

.important-work-button {
  background-color: transparent;
  color: var(--gold-dark);
  padding: 10px 20px;
  margin-top: 10px;
  border: solid 1px var(--gold-dark);
  text-decoration: none;
  font-size: 1em;
  transition: 0.5s;
  cursor: pointer;
  letter-spacing: 1.2px;
}

.important-work-button:hover {
  background-color: var(--gold-dark);
  color: var(--cream-light);
}

.work-description {
  flex: 1;
  max-width: 500px;
  margin-left: 100px;
  color: var(--gold-dark);
  text-align: left;
}

.work-description h2 {
  font-size: 2.4em;
  color: var(--gold-dark);
  margin: 10px 0 20px 0;
}

.work-description p {
  font-size: 1em;
  line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .work-description {
    margin-left: 50px;
  }

  .important-work img {
    height: 400px;
  }
}

@media (max-width: 992px) {
  .work-description {
    margin-left: 20px;
  }

  .important-work img {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .important-work-item {
    flex-direction: column;
  }

  .important-work-wrapper {
    padding: 0px 0;
  }

  .important-work img {
    margin-right: 0;
    margin-bottom: 20px;
    height: auto;
  }

  .work-description {
    margin-left: 0;
    text-align: center;
  }

  .work-description h2 {
    font-size: 2em;
  }

  .work-description p {
    font-size: 1em;
  }
}

@media (max-width: 576px) {
  .important-work img {
    width: 90%;
  }

  .work-description h2 {
    font-size: 2em;
  }

  .work-description p {
    font-size: 1em;
    width: 85%;
    text-align: left;
    margin-inline: auto;
  }
}
