@font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-Bold-Trial.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-BoldItalic-Trial.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-Light-Trial.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-LightItalic-Trial.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-Medium-Trial.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-MediumItalic-Trial.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-Regular-Trial.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-RegularItalic-Trial.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-Thin-Trial.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ABCArizonaMix';
    src: url('./fonts/ABCArizonaMix-ThinItalic-Trial.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
  }
  