@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins';
  background-color: var(--cream-light);
  overflow-x: none;
}

h1, h2, h3, h4{
  font-family: 'ABCArizonaMix', sans-serif;
}

h1{
  font-size: 48px;
}

p, a{
  font-family: 'Poppins';
  font-weight: 400;
}

.App {
  text-align: center;
}
