.contact-form {
  padding: 50px 20px;
  background-color: var(--cream-light);
  text-align: center;
  max-width: 1050px;
  margin-inline: auto;
}

.contact-details-wrapper {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.contact-details-wrapper p {
  margin: 0;
  color: var(--gold-dark);
}

.contact-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.contact-form h2 {
  font-size: 2.4em;
  margin-bottom: 20px;
  color: var(--gold);
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.form-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact-form label {
  width: 100%;
  max-width: 520px;
  margin: 10px 0;
  text-align: left;
  color: var(--gold-dark);
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  width: 100%;
  max-width: 520px;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
  border: none;
  padding: 10px 0;
  background-color: transparent;
  border-bottom: solid 2px var(--gold);
  color: var(--gold);
  font-family: 'Poppins';
}

.contact-form ::placeholder, .contact-form option{
  color: var(--gold);
}

.contact-form button {
  padding: 10px 40px;
  background-color: var(--gold-dark);
  color: var(--cream-light);
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1rem;
}

.contact-form button:hover {
  background-color: var(--gold);
}

/* Responsive Styles */
@media (max-width: 992px) {
  .contact-details-wrapper,
  .contact-form form {
    width: 90%;
  }

  .contact-form {
    padding: 30px 15px;
  }

  .contact-form h2 {
    font-size: 1.8em;
  }

  .contact-form label {
    margin: 5px 0;
  }

  .contact-form input,
  .contact-form textarea {
    max-width: 95%;
    padding: 16px 8px;
  }

  .contact-form button {
    padding: 10px 30px;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .contact-form {
    padding: 20px 10px;
  }

  .contact-form h2 {
    font-size: 1.6em;
  }

  .contact-form label {
    font-size: 0.9em;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.9em;
  }

  .contact-form button {
    padding: 8px 20px;
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .contact-form {
    padding: 15px 5px;
  }

  .contact-details-wrapper p {
  font-size: 1.2rem;
  text-align: left;
  margin: 20px 0;
  }

  .contact-form h2 {
    font-size: 2em;
  }

  .contact-form label {
    font-size: 1em;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 1em;
  }

  .contact-form button {
    padding: 10px 30px;
    font-size: 1.2rem;
  }
}
