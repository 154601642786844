.gallery {
  position: relative;
  height: 80vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gold-grey);
}

.gallery-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.gallery-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.blurred-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 1;
  filter: blur(5px);
  opacity: 0.8;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--cream-light);
  border: none;
  padding: 20px;
  cursor: pointer;
  z-index: 1;
}

.nav-button.left {
  left: 0px;
}

.nav-button.right {
  right: 0px;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .gallery {
    height: 60vh;
  }
}